import {makeAutoObservable} from "mobx";
import {AppSelectProps, OptionsProps} from "../Select/AppSelect";
import {BasicDatePickerProps} from "../BasicDatePicker/BasicDatePicker";
import * as React from "react";
interface BaseInputProps {
    value: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

class PatientInfoStore {
    valueGroupBlood: string = '';
    valueFindToASA: string = '';
    valueRh: string = '';
    valueDepartment: string = '';
    valueOperation: string = '';
    valueVolumeOperation: string = '';
    valueIVL: string = '';
    valueMonitoring: string = '';
    dateOperation: Date | null = null;
    weight: string = '';
    height: string = '';

    get optionsGroupBlood(): OptionsProps[] {
        return [
            {
                value: '1',
                title: 'I',
            },
            {
                value: '2',
                title: 'II',
            },
            {
                value: '3',
                title: 'III',
            },
            {
                value: '4',
                title: 'IV',
            }
        ]
    }

    get groupBlood(): AppSelectProps {
        return {
            options: this.optionsGroupBlood,
            value: this.valueGroupBlood,
            onChange: this.onChangeGroupBlood
        }
    }

    get optionsFindToASA(): OptionsProps[] {
        return [
            {
                value: '1',
                title: 'I',
            },
            {
                value: '2',
                title: 'II',
            },
            {
                value: '3',
                title: 'III',
            },
            {
                value: '4',
                title: 'IV',
            },
            {
                value: '5',
                title: 'V',
            }
        ]
    }

    get FindToASA(): AppSelectProps {
        return {
            options: this.optionsFindToASA,
            value: this.valueFindToASA,
            onChange: this.onChangeFindToASA
        }
    }

    get optionsRh(): OptionsProps[] {
        return [
            {
                value: '+',
                title: 'Rh (+)',
            },
            {
                value: '-',
                title: 'Rh (-)',
            }
        ]
    }

    get Rh(): AppSelectProps {
        return {
            options: this.optionsRh,
            value: this.valueRh,
            onChange: this.onChangeRh
        }
    }

    get optionsDepartment(): OptionsProps[] {
        return [
            {
                value: 'КПХ',
                title: 'Хирургическое отделение №1 КПХ',
            },
            {
                value: 'КФХ',
                title: 'Хирургическое отделение №1 КФХ',
            },
            {
                value: 'КПХ2',
                title: 'Хирургическое отделение №2 КПХ (гнойная хирургия)',
            },
            {
                value: 'КФХ2',
                title: 'Хирургическое отделение №2 КФХ (эндокринная хирургия)',
            },
            {
                value: 'ХОПО',
                title: 'Хирургическое отделение пересадки органов',
            },
            {
                value: 'АРО',
                title: 'Анестезиологии-реанимации отделение',
            }
        ]
    }

    get Department(): AppSelectProps {
        return {
            options: this.optionsDepartment,
            value: this.valueDepartment,
            onChange: this.onChangeDepartment
        }
    }

    get optionsOperation(): OptionsProps[] {
        return [
            {
                value: 'Неотложная',
                title: 'Неотложная',
            },
            {
                value: 'Плановая',
                title: 'Плановая',
            }
        ]
    }

    get Operation(): AppSelectProps {
        return {
            options: this.optionsOperation,
            value: this.valueOperation,
            onChange: this.onChangeOperation
        }
    }

    get optionsVolumeOperation(): OptionsProps[] {
        return [
            {
                value: '1',
                title: '1',
            },
            {
                value: '2',
                title: '2',
            },
            {
                value: '3',
                title: '3',
            },
            {
                value: '4',
                title: '4',
            }
        ]
    }

    get VolumeOperation(): AppSelectProps {
        return {
            options: this.optionsVolumeOperation,
            value: this.valueVolumeOperation,
            onChange: this.onChangeVolumeOperation
        }
    }

    get optionsIVL(): OptionsProps[] {
        return [
            {
                value: 'Mindray',
                title: 'Mindray',
            },
            {
                value: 'Drager',
                title: 'Drager',
            },
            {
                value: 'GE',
                title: 'GE',
            }
        ]
    }

    get IVL(): AppSelectProps {
        return {
            options: this.optionsIVL,
            value: this.valueIVL,
            onChange: this.onChangeIVL
        }
    }

    get optionsMonitoring(): OptionsProps[] {
        return [
            {
                value: 'Comen',
                title: 'Comen',
            },
            {
                value: 'Mindray',
                title: 'Mindray',
            }
        ]
    }

    get Monitoring(): AppSelectProps {
        return {
            options: this.optionsMonitoring,
            value: this.valueMonitoring,
            onChange: this.onChangeMonitoring
        }
    }

    get operationDate(): BasicDatePickerProps {
        return {
            value: this.dateOperation,
            onChange: this.onChangeOperationDate
        }
    }

    get patientWeight(): BaseInputProps {
        return {
            value: this.weight,
            onChange: this.onChangeWeight
        }
    }

    get patientHeight(): BaseInputProps {
        return {
            value: this.height,
            onChange: this.onChangeHeight
        }
    }

    constructor() {
        makeAutoObservable(this)
    }

    onChangeGroupBlood = (event: any) => {
        const {target: {value}} = event;
        this.valueGroupBlood = value
    }

    onChangeFindToASA = (event: any) => {
        const {target: {value}} = event;
        this.valueFindToASA = value
    }

    onChangeRh = (event: any) => {
        const {target: {value}} = event;
        this.valueRh = value
    }

    onChangeDepartment = (event: any) => {
        const {target: {value}} = event;
        this.valueDepartment = value
    }

    onChangeOperation = (event: any) => {
        const {target: {value}} = event;
        this.valueOperation = value
    }

    onChangeVolumeOperation = (event: any) => {
        const {target: {value}} = event;
        this.valueVolumeOperation = value
    }

    onChangeIVL = (event: any) => {
        const {target: {value}} = event;
        this.valueIVL = value
    }

    onChangeMonitoring = (event: any) => {
        const {target: {value}} = event;
        this.valueMonitoring = value
    }

    onChangeOperationDate = (date: Date | null) => {
        this.dateOperation = date;
    }

    onChangeWeight = (event: any) => {
        const {target: {value}} = event;
        this.weight = value;
    }

    onChangeHeight = (event: any) => {
        const {target: {value}} = event;
        this.height = value;
    }
}

export default PatientInfoStore