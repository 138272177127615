import {observer} from "mobx-react-lite";
import './Premedication.css'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {
    Fade, FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput, Tooltip
} from "@mui/material";
import React, {useState} from "react";
import PremedicationStore from "./Premedication.store";

interface PremedicationProps{
    medications: string[];
}

const Premedication = (props:PremedicationProps) => {
    let [state] = useState(() => new PremedicationStore())
    return <>
        <h4>
            <span>O2  л/мин &nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>N2O  л/мин</span>
        </h4>
        <table>
            <thead>
            <tr>
                <th>Препарат \ Время</th>
                <th>10:00</th>
                <th>10:01</th>
                <th>10:02</th>
                <th>10:03</th>
                <th>10:04</th>
                <th>10:05</th>
                <th>10:06</th>
                <th>10:07</th>
                <th>10:08</th>
                <th>10:09</th>
                <th>10:10</th>
                <th>10:11</th>
                <th>Действия</th>
            </tr>
            </thead>
            <tbody>
            {state.medications.map((item, index) => {
                return <tr key={index}>
                    <td>
                        <div className="medication">
                            <span>{item.value}</span>
                            <div className="field">
                                <FormControl fullWidth>
                                    <InputLabel id={"amount-item_" + index + "-label"}>Обьём</InputLabel>
                                    <OutlinedInput
                                        label="Обьём"
                                        id={"amount-item_" + index}
                                        value={item.amount}
                                        disabled={item.isStart}
                                        onChange={(event) => item.amount = event.currentTarget.value ? parseFloat(event.currentTarget.value) : 0}
                                        endAdornment={<InputAdornment position="end">мл</InputAdornment>}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </td>
                    <td>{item.processValueInterval.length > 0 ?
                        <Tooltip title={item.processValueInterval[0]} arrow>
                            <span>{item.processValueInterval[0]}</span>
                        </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 1 ?
                        <Tooltip title={item.processValueInterval[1]} arrow>
                        <span>{item.processValueInterval[1]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 2 ?
                        <Tooltip title={item.processValueInterval[2]} arrow>
                        <span>{item.processValueInterval[2]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 3 ?
                        <Tooltip title={item.processValueInterval[3]} arrow>
                        <span>{item.processValueInterval[3]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 4 ?
                        <Tooltip title={item.processValueInterval[4]} arrow>
                        <span>{item.processValueInterval[4]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 5 ?
                        <Tooltip title={item.processValueInterval[5]} arrow>
                        <span>{item.processValueInterval[5]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 6 ?
                        <Tooltip title={item.processValueInterval[6]} arrow>
                        <span>{item.processValueInterval[6]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 7 ?
                        <Tooltip title={item.processValueInterval[7]} arrow>
                        <span>{item.processValueInterval[7]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 8 ?
                        <Tooltip title={item.processValueInterval[8]} arrow>
                        <span>{item.processValueInterval[8]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 9 ?
                        <Tooltip title={item.processValueInterval[9]} arrow>
                        <span>{item.processValueInterval[9]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 10 ?
                        <Tooltip title={item.processValueInterval[10]} arrow>
                        <span>{item.processValueInterval[10]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.processValueInterval.length > 11 ?
                        <Tooltip title={item.processValueInterval[11]} arrow>
                        <span>{item.processValueInterval[11]}</span>
                    </Tooltip> : "V"}</td>
                    <td>{item.amount > 0 ?
                        <IconButton
                            id="fade-button"
                            className="btn-svg"
                            onClick={() => state.toggleMedications(item)}
                        >
                            {item.isStart ? <StopIcon className="color-stop"/> :
                                <PlayArrowIcon className="color-start"/>}
                        </IconButton>
                        : "V"}</td>
                </tr>
            })}
            <tr>
                <td>Добавить препарат
                    <IconButton
                        id="fade-button"
                        className={state.open ? 'btn-svg ml-10 rotate45' : 'btn-svg ml-10'}
                        aria-controls={state.open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={state.open ? 'true' : undefined}
                        onClick={state.handleClick}
                    >
                        <AddCircleOutlineIcon/>
                    </IconButton>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={state.anchorEl}
                        open={state.open}
                        onClose={state.handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                            },
                        }}
                        TransitionComponent={Fade}
                    >
                        {props.medications.map((item,index) => <MenuItem key={index} onClick={state.handleClose}>{item}</MenuItem>)}
                    </Menu>
                </td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
                <td>V</td>
            </tr>
            </tbody>
        </table>
    </>
}

export default observer(Premedication)