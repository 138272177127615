import {makeAutoObservable} from "mobx";
import React from "react";

interface MedicationsProps {
    value: string;
    amount: number;
    valueInterval: number;
    processValueInterval: number[];
    idInterval: any;
    process: number;
    isStart: boolean
}

class PremedicationStore {
    anchorEl: null | HTMLElement = null;
    medications: MedicationsProps[] = [];

    get open() {
        return Boolean(this.anchorEl);
    }

    constructor() {
        makeAutoObservable(this);
    }

    toggleMedications = (item: MedicationsProps) => {
        item.isStart = !item.isStart
        if (item.isStart) {
            item.valueInterval = parseFloat((item.amount / 12).toFixed(1));
            item.idInterval = setInterval(() => {
                if (item.processValueInterval.length <= 12) {
                    item.processValueInterval.push(item.valueInterval);
                }else{
                    if(item.idInterval){
                        clearInterval(item.idInterval)
                    }
                }
            }, 1000);
        }else{
            if(item.idInterval){
                clearInterval(item.idInterval)
            }
        }
    }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.anchorEl = event.currentTarget;
    };

    handleClose = (event: any) => {
        const {currentTarget: {innerText}} = event;
        if (innerText) {
            this.medications.push({
                value: innerText,
                amount: 0,
                isStart: false,
                valueInterval: 0,
                process: 0,
                processValueInterval: [],
                idInterval: null
            });
        }
        this.anchorEl = null;
    };
}

export default PremedicationStore