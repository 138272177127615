import {DatePicker, DatePickerProps, LocalizationProvider} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {observer} from "mobx-react-lite";
import './BasicDatePicker.css';

export interface BasicDatePickerProps {
    value: Date | null;
    onChange: (value: Date | null, keyboardInputValue?: string) => void
}

const BasicDatePicker = (props: BasicDatePickerProps) => {
    return <div className="BaseDatePicker">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                mask="__/__/____"
                value={props.value}
                onChange={props.onChange}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    </div>;
}

export default observer(BasicDatePicker)