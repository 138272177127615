import {observer} from "mobx-react-lite";
import PatientInfo from "../PatientInfo/PatientInfo";
import './App.css'
import Premedication from "../Premedication/Premedication";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";

const preMedications = [
    "Этаминал натрий",
    "Фенобарбитал",
    "Радедорм",
    "Нозепам",
    "Тозепам",
    "Диазепам",
    "Феназепам"
]

const introductionsMedications = [
    "Пропофол",
    "Кетамин",
    "Тиопентал натрия",
    "Севоран",
    "Азота закись",
    "Диприван",
    "Пропофол Каби",
    "Мидазолам",
    "Фторотан",
    "Пропофол-Липуро",
    "Севофлуран",
    "Аерран",
    "Супран",
    "Рекофол",
    "Галотан",
    "Натрия оксибат",
    "КсеМед",
    "Провайв",
    "Натрия оксибутират",
    "Форан",
    "Севофлуран-Виал",
    "Пропофол-Ново",
    "Пропофол-Бинергия",
    "Эфир медицинский",
    "Севофлуран Медисорб",
    "Кетамина гидрохлорид"
];

const supportMedicationsAnastesia = [
    "Пропофол",
    "Кетамин",
    "Тиопентал натрия",
    "Севоран",
    "Азота закись",
    "Диприван",
    "Пропофол Каби",
    "Мидазолам",
    "Фторотан",
    "Пропофол-Липуро",
    "Севофлуран",
    "Аерран",
    "Супран",
    "Рекофол",
    "Галотан",
    "Натрия оксибат",
    "КсеМед",
    "Провайв",
    "Натрия оксибутират",
    'Форан',
    "Севофлуран-Виал",
    "Пропофол-Ново",
    "Пропофол-Бинергия",
    "Эфир медицинский",
    "Севофлуран Медисорб",
    "Кетамина гидрохлорид"
];


const App = () => {
    return <div className="App">
        <PatientInfo/>
        <div className="nextBlock">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h1>Премедикация</h1>
                </AccordionSummary>
                <AccordionDetails>
                    <Premedication medications={preMedications}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h1>Введение в анестезию</h1>
                </AccordionSummary>
                <AccordionDetails>
                    <Premedication medications={introductionsMedications}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h1>Поддержание анестезии</h1>
                </AccordionSummary>
                <AccordionDetails>
                    <Premedication medications={supportMedicationsAnastesia}/>
                </AccordionDetails>
            </Accordion>
        </div>

    </div>;
}

export default observer(App);
