import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import './PatientInfo.css'
import AppSelect from "../Select/AppSelect";
import PatientInfoStore from "./PatientInfo.store";
import AppSwitch from "../AppSwitch/AppSwitch";
import BasicDatePicker from "../BasicDatePicker/BasicDatePicker";
import {InputAdornment, OutlinedInput} from "@mui/material";

const PatientInfo = () => {
    let [state] = useState(() => new PatientInfoStore())

    return <>
        <h1>Наркозная карта</h1>
        <div className="patient-info">
            <h3>Сведения о пациенте:</h3>
            <hr className="hr-dashed"/>
            <div className="patient-container">
                <div className="field box-a">
                    <span className="label">ФИО:</span>
                    <input type="text" placeholder="Введите ФИО"/>
                </div>
                <div className="field box-b">
                    <span className="label">Рост:</span>
                    <OutlinedInput
                        value={state.patientHeight.value}
                        onChange={state.patientHeight.onChange}
                        endAdornment={<InputAdornment position="end">см</InputAdornment>}
                    />
                </div>
                <div className="field box-c">
                    <span className="label">Группа крови:</span>
                    <AppSelect onChange={state.groupBlood.onChange}
                               options={state.groupBlood.options}
                               placeholder="Выбрать"
                               value={state.groupBlood.value}></AppSelect>
                </div>
                <div className="field box-d">
                    <span className="label">Rh:</span>
                    <AppSelect onChange={state.Rh.onChange}
                               options={state.Rh.options}
                               placeholder="Выбрать тип"
                               value={state.Rh.value}></AppSelect>
                </div>
                <div className="field box-e">
                    <span className="label">Диагноз:</span>
                    <input type="text" placeholder="Введите диагноз"/>
                </div>
                <div className="field box-g">
                    <span className="label">Вес:</span>
                    <OutlinedInput
                        value={state.patientWeight.value}
                        onChange={state.patientWeight.onChange}
                        endAdornment={<InputAdornment position="end">кг</InputAdornment>}
                    />
                </div>
                <div className="field box-f">
                    <span className="label">Риск по ASA:</span>
                    <AppSelect onChange={state.FindToASA.onChange}
                               options={state.FindToASA.options}
                               placeholder="Выбрать"
                               value={state.FindToASA.value}></AppSelect>
                </div>
                <div className="field-empty box-k"></div>
                <div className="field box-l">
                    <span className="label">Дата операции:</span>
                    <BasicDatePicker value={state.operationDate.value} onChange={state.operationDate.onChange}/>
                </div>
                <div className="field box-m">
                    <span className="label">Отделение:</span>
                    <AppSelect onChange={state.Department.onChange}
                               options={state.Department.options}
                               placeholder="Выбрать отделение"
                               value={state.Department.value}></AppSelect>
                </div>
                <div className="field box-n">
                    <span className="label">Операция:</span>
                    <AppSelect onChange={state.Operation.onChange}
                               options={state.Operation.options}
                               placeholder="Выбрать тип"
                               value={state.Operation.value}></AppSelect>
                </div>
                <div className="field box-o">
                    <span className="label">По объему операции:</span>
                    <AppSelect onChange={state.VolumeOperation.onChange}
                               options={state.VolumeOperation.options}
                               placeholder="Выбрать"
                               value={state.VolumeOperation.value}></AppSelect>
                </div>
                <div className="field box-p">
                    <span className="label">Непереносимость препаратов:</span>
                    <textarea placeholder="Введите название"></textarea>
                </div>
                <div className="field box-r ta-c customBlockSwitch">
                    <span className="label">Интубация трахеи:</span>
                    <AppSwitch/>
                </div>
                <div className="field-empty box-s"></div>
                <div className="field box-t">
                    <span className="label">Аппарат ИВЛ (НДА):</span>
                    <AppSelect onChange={state.IVL.onChange}
                               options={state.IVL.options}
                               placeholder="Выбрать тип"
                               value={state.IVL.value}></AppSelect>
                </div>
                <div className="field box-u">
                    <span className="label">Мониторинг:</span>
                    <AppSelect onChange={state.Monitoring.onChange}
                               options={state.Monitoring.options}
                               placeholder="Выбрать тип"
                               value={state.Monitoring.value}></AppSelect>
                </div>
            </div>
        </div>
    </>
}

export default observer(PatientInfo);
