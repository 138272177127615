import React from "react";
import {observer} from "mobx-react-lite";
import {FormControl, MenuItem, Select} from "@mui/material";
import './AppSelect.css'
import {SelectInputProps} from "@mui/material/Select/SelectInput";

export interface OptionsProps {
    value: string;
    title: string;
}

export interface AppSelectProps<T = unknown> {
    onChange: SelectInputProps<T>['onChange'];
    options: OptionsProps[];
    value: string;
    placeholder?: string;
}

const AppSelect = (props: AppSelectProps) => {
    return <FormControl fullWidth>
        <Select classes={{select: 'customStyleSelect'}}
            displayEmpty
            onChange={props.onChange}
            value={props.value}>
            {props.placeholder && <MenuItem disabled value={``}>
              <em>{props.placeholder}</em>
            </MenuItem>}
            {props.options.map( (item, index) => <MenuItem key={index} value={item.value}>{item.title}</MenuItem>)}
        </Select>
    </FormControl>
}

export default observer(AppSelect)